/**
 * https://docs.sentry.io/platforms/javascript/react/
 */
import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

import Paper from "@material-ui/core/Paper";
import withProfile from "HOC/withProfile";
import s from "./ErrorBoundary.module.css";

class ErrorBoundary extends Component {
  state = {
    error: null,
    eventId: null
  };
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (process.env.NODE_ENV !== "development") {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }
  render() {
    const { token = {}, profile = {} } = this.props;
    if (this.state.error) {
      return (
        <div className={s.container}>
          <div className={s.backgroundImage}>
            <Paper className={s.paper}>
              <div className={s.title}>Uh Oh! Something Went Wrong</div>
              <button
                className={s.report}
                onClick={() =>
                  Sentry.showReportDialog({
                    eventId: this.state.eventId,
                    user: {
                      name: profile.fullName,
                      email: token.email
                    }
                  })
                }
              >
                Report feedback
              </button>
              <button
                className={s.refresh}
                onClick={() => window.location.reload()}
              >
                Refresh Page
              </button>
            </Paper>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withProfile(ErrorBoundary);
