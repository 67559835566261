/**
 * This file is responsible for both
 * 1) sending the token with every response to the backend server
 * 2) refreshing the token if the expiration time is within 5 minutes or less.
 */

import axios from 'axios';
import history from 'utils/history';

import { API } from 'config';

const token = localStorage.getItem('jwt');

export default async () => {
  try {
    const {
      status,
      data: { token: newToken },
    } = await axios.post(
      `${API}/refresh/`,
      {
        token,
      },
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    );
    if (status === 200) {
      localStorage.setItem('jwt', newToken);
      window.location.reload();
    }
  } catch (e) {
    history.push('/login');
  }
};
