import * as options from "constants/options";
import isUrl from "validator/lib/isURL";

export const redirectByKey = (keyToMatch) => {
  return Object.values(organizationQuestions).findIndex(
    ({ key }) => key === keyToMatch
  );
};

export const redirectByRouteName = (keyToMatch) => {
  return Object.keys(organizationQuestions).findIndex(
    (key) => key === keyToMatch
  );
};

export const isBoolean = (q) =>
  q === false || q === true || q === "true" || q === "false";

const organizationQuestions = {
  primary_address: {
    key: "primaryAddress",
    variant: "contactInfo",
    action: "saveEmployerAddress",
    question: "Enter your Organization's Primary Address:",
    helperText: "This is used to verify that you are a US based organization.",
    isValid: (p) =>
      p?.primaryAddress?.streetAddress &&
      p?.primaryAddress?.zipcode &&
      p?.primaryAddress?.state &&
      p?.primaryAddress?.city,
  },
  metro_area: {
    key: "metroArea",
    closeOnSelect: true,
    subSection: "Location Preferences",
    question: "Organization Headquarters Metro Area:",
    validate: (v) => v,
    transform: (c) => ({
      metroArea: c?.metroArea?.label,
    }),
    isValid: (p) => p?.metroArea,
    action: "updateEmployer",
    variant: "createableselect",
    createNewOptionText: "Add your own (include your state's abbreviation):",
    options: (context) => context.cities,
    instructions:
      "Choose from the options below or -- if you don't live in one of the top 100 metro areas -- add a custom city.",
    isMulti: false,
    isValidNewOption: (inputValue) => {
      if (!inputValue || !inputValue.includes(",")) {
        return false;
      }
      const [rawCity, rawState] = inputValue.split(",");
      const city = rawCity.trim("");
      const state = rawState.trim("");

      if (city[0] !== city[0].toUpperCase()) {
        return false;
      }

      if (state.toUpperCase() !== state) {
        return false;
      }

      return true;
    },
  },
  link: {
    key: "link",
    doWarn: (p) =>
      p.link?.length && !p.link?.includes("http")
        ? "Link must include a protocol (https://)"
        : null,
    variant: "textField",
    type: "link",
    helperText:
      'Either link to your home page or to an "About Us" page. This link will be provided to JobSeekers who are matches for your roles',
    question: "What is your organization's homepage?",
    isValid: (p) => p?.link && isUrl(p.link) && p.link.includes("http"),
    action: "updateEmployer",
  },
  logos: {
    key: "logos",
    variant: "logoUpload",
    helperText:
      "Click on the placeholder images below to select an image. This is optional, but highly recommended. If you do not upload a logo, a default logo will appear on your profile.",
    question: "Select Logos for your Organization:",
  },
  education_type: {
    key: "educationType",
    question: "Which type of education organization are you?",
    helperText:
      "WorkMonger works primarily with US-based education organizations.",
    variant: "radio",
    options: options.educationTypes.map((v) => ({ value: v, label: v })),
    isValid: (p) => p?.educationType,
    action: "updateEmployer",
  },
  mission_statement: {
    key: "missionStatement",
    variant: "markdown",
    action: "updateEmployer",
    disabledKeyboardEvents: true,
    helperText:
      "Add your mission statement below. If you want to format your mission a certain way, you can use markdown format. This is one of the first things that JobSeekers will see when they view your profile.",
    isValid: (p) => p?.missionStatement,
    question: "What is the mission of your organization?",
  },
  company_size: {
    key: "companySize",
    question: "What is the current size of the organization?",
    variant: "radio",
    helperText: "Select from the options below",
    options: options.companySize.map((v) => ({ value: v, label: v })),
    isValid: (p) => p.companySize,
    action: "updateEmployer",
  },
  growth_stage: {
    key: "growthStage",
    question: "What is the current growth stage of the organization?",
    helperText: "Select from the options below",
    variant: "radio",
    options: options.growthStages.map((v) => ({ value: v, label: v })),
    isValid: (p) => p.growthStage,
    action: "updateEmployer",
  },
  personality_traits: {
    key: "personalityTraits",
    subSection: "Personality",
    style: {
      height: 400,
    },
    variant: "multi",
    helperText: "Pick up to 5 Options",
    doWarn: (p) =>
      p.personalityTraits?.length > 5 ? "Only limit your selection to 5" : null,
    isValid: (p) => p.personalityTraits?.length === 5,
    action: "updateEmployer",
    employerField: true,
    question:
      "What are the top 5 attributes that describe your organizational culture?",
    options: options.personalityTraits.map((v) => ({ value: v, label: v })),
  },
  employees_have_close_relationships: {
    key: [
      "employeesHaveCloseRelationships",
      "hasPotentialForHighGrowth",
      "hasOpportunityForAdvancement",
      "hasWorkLifeBalance",
    ],
    question:
      "How strongly do these attributes describe your organization’s culture?",
    helperText: "Select from the options below:",
    variant: "forceRank",
    allowMultiple: true,
    employerField: true,
    isValid: (p) => p.employeesHaveCloseRelationships,
    options: options.employeeRelationshipTraits,
    action: "updateEmployer",
    customRankings: ["Not at All", "Neutral", "Somewhat", "Strongly"],
  },
  culture_type: {
    key: "cultureType",
    variant: "cultureType",
    helperText: "Select which box best describes your organization's culture.",
    question:
      "Which of the following culture types most closely reflects your organization's culture?",
    action: "updateEmployer",
    isValid: (p) => p.cultureType,
    style: {
      maxHeight: "none",
    },
  },
  referral_type: {
    key: "referralType",
    question: "How did you hear about WorkMonger?",
    closeOnSelect: true,
    createNewOptionText:
      "I heard about WorkMonger from (type to create custom option):",
    isValidOption: (i) => i?.length,
    isValid: (p) => p?.referralType,
    action: "updateEmployerReferralType",
    variant: "createableselect",
    options: options.referralTypes.map((v) => ({
      label: v,
      value: v,
    })),
    placeholder: "Referral Type",
    instructions:
      "Choose from the options below or -- if you don't see a matching option -- type and select your own.",
    isMulti: true,
  },
  intro_to_role_section: {
    key: "introToRoleSection",
    question: "Company Profile Complete!",
    variant: "introToSection",
    disabledKeyboardEvents: true,
    isValid: (p) => true,
  },
  associated_contract: {
    key: "associatedContract",
    question: "Choose an Associated Contract:",
    variant: "associatedContract",
    action: "updateRole",
  },
  job_title: {
    key: "jobTitle",
    question: "What is the exact job title?",
    variant: "textField",
    disabledKeyboardEvents: true,
    maxChars: 72,
    placeholder: "E.g. Underwater Basket Weaver",
    helperText:
      "We will generate a unique Job ID so job titles do not need to be unique.",
    isValid: (p) => p.jobTitle,
    action: "updateRole",
  },
  description: {
    key: "description",
    question: "Share an overview of what you are hiring this person to do:",
    variant: "textField",
    disabledKeyboardEvents: true,
    placeholder: "Job Description",
    multiline: true,
    rows: 6,
    maxChars: 500,
    helperText:
      "Just the high points or summary of work responsibilities will do.  And don't worry - we'll ask more detailed, specific questions as the process continues (as well as give you an option to upload a job description), but you have to start somewhere!",
    isValid: (p) => p.description && p.description?.length < 501,
    action: "updateRole",
  },
  job_dates: {
    key: ["idealStartDate", "latestStartDate"],
    helperText:
      "Either enter the dates by typing or by clicking on the calendar icons",
    question: "Enter the ideal and latest start date:",
    variant: "jobDates",
    action: "updateRole",
    isValid: (p) => p.idealStartDate && p.latestStartDate,
  },
  role_type: {
    key: "roleType",
    question: "What type of role are you hiring for?",
    options: options.roleTypes,
    helperText: "Check all that you are open to",
    isValid: (r) => r.roleType?.length,
    variant: "multi",
    action: "updateRole",
  },
  role_length: {
    key: "roleLength",
    question: "What is the length of employment for this new position?",
    options: options.roleLengths,
    helperText:
      "We know that some work takes longer. Choose all of the options that would work for this position.",
    isValid: (r) => r.roleLength?.length,
    variant: "multi",
    action: "updateRole",
  },
  role_location_type: {
    key: "roleLocationType",
    question: "What is the work structure for this role?",
    options: options.roleLocationType,
    helperText: "Choose all that apply",
    isValid: (r) => r.roleLocationType?.length,
    variant: "multi",
    action: "updateRole",
  },
  geographic_requirement: {
    next: (p, role) => {
      if (role.geographicRequirement === "specific") {
        return redirectByKey("availableMetroAreas");
      }
      const isFullTime = role.roleType.includes("full-time");
      if (isFullTime) {
        return redirectByKey("openToRelocation");
      }

      return redirectByKey("preferredRate");
    },
    key: "geographicRequirement",
    question: "Where is this role located?",
    options: options.geographicRequirement,
    helperText:
      "WorkMonger encourages you to be as flexible as possible. You never know if your dream candidate is a state away.",
    isValid: (r) => r.geographicRequirement,
    variant: "radio",
    action: "updateRole",
  },
  available_metro_areas: {
    key: "availableMetroAreas",
    question: "In what cities would you consider locating this hire?",
    closeOnSelect: true,
    isMulti: true,
    transform: (r) => ({
      availableMetroAreas: r?.availableMetroAreas.map(({ value }) => value),
    }),
    isValid: (r) =>
      r?.geographicRequirement === "specific"
        ? r.availableMetroAreas?.length
        : true,
    action: "updateRole",
    variant: "createableselect",
    createNewOptionText: "Add your own (include your state's abbreviation): ",
    options: (context) => context.cities,
    placeholder: "-- Select --",
    instructions: "Select Multiple Options from the list below",
    next: (p, role) => {
      const isFullTime = role.roleType.includes("full-time");
      if (isFullTime) {
        return redirectByKey("openToRelocation");
      }

      return redirectByKey("preferredRate");
    },
  },
  open_to_relocation: {
    key: "openToRelocation",
    back: (p, role) => {
      if (role.geographicRequirement === "specific") {
        return redirectByKey("availableMetroAreas");
      }
      return "geographicRequirement";
    },
    question:
      "Are you open to candidates relocating for this employment opportunity?",
    options: options.booleanOptions,
    helperText: "We recommend remaining as flexible as possible!",
    isValid: (r) =>
      !r.roleType?.includes("full-time") || isBoolean(r.openToRelocation),
    variant: "radio",
    action: "updateRole",
    next: (p, role) => {
      if (role.openToRelocation === "true" || role.openToRelocation === true) {
        return redirectByKey("offersRelocation");
      }
      return redirectByKey("preferredSalary");
    },
  },
  offers_relocation: {
    key: "offersRelocation",
    question: "Do you offer relocation benefits for this role?",
    helperText: "Select from the options below",
    options: options.booleanOptions,
    variant: "radio",
    action: "updateRole",
  },
  preferred_salary: {
    key: "preferredSalary",
    back: (p, role) => {
      if (role.openToRelocation === "true" || role.openToRelocation === true) {
        return redirectByKey("offersRelocation");
      }
      return redirectByKey("openToRelocation");
    },
    question: "What is your preferred salary range for this role",
    helperText:
      "This is what you would like to pay for this role. We'll ask you next what you would pay for your ideal candidate",
    variant: "compensation",
    action: "updateRole",
  },
  maximum_salary: {
    key: "maximumSalary",
    helperText: "Select from the options below",
    question:
      "For an extremely strong candidate, we would be willing to pay within this maximum range:",
    variant: "compensation",
    action: "updateRole",
    next: (p, role) => {
      const includesPartTime = !!role.roleType.filter(
        (value) => value !== "full-time"
      ).length;
      if (includesPartTime) {
        return redirectByKey("preferredRate");
      }
      return redirectByKey("requiresTravel");
    },
  },
  preferred_rate: {
    key: "preferredRate",
    question: "What is your preferred rate for this role?",
    helperText: "This applies to any part-time or contract work",
    variant: "compensation",
    action: "updateRole",
    back: (p, role) => {
      const includesFullTime = !!role.roleType.filter(
        (value) => value === "full-time"
      ).length;
      if (includesFullTime) {
        return redirectByKey("maximumSalary");
      }
      if (role.openToRelocation === "true" || role.openToRelocation === true) {
        return redirectByKey("offersRelocation");
      }
      return "openToRelocation";
    },
  },
  maximum_rate: {
    key: "maximumRate",
    helperText: "This applies to any part-time or contract work",
    question:
      "For an extremely strong candidate, we would be willing to pay up to a maximum of this rate:",
    variant: "compensation",
    action: "updateRole",
  },
  role_benefits: {
    key: "roleBenefits",
    question: "What benefits does this role offer?",
    helperText: "Select from the options below",
    options: options.roleBenefits,
    variant: "multi",
    style: {
      height: 400,
    },
    action: "updateRole",
    back: (p, role) => {
      const includesPartTime = !!role.roleType.filter(
        (value) => value !== "full-time"
      ).length;
      if (includesPartTime) {
        return redirectByKey("maximumRate");
      }
      return "maximumSalary";
    },
  },
  requires_travel: {
    key: "requiresTravel",
    question: "Does this role require travel?",
    helperText:
      "If you answer YES, we'll ask for more details in the next question",
    options: options.booleanOptions,
    variant: "radio",
    action: "updateRole",
    next: (p, role) => {
      if (role.requiresTravel === "true" || role.requiresTravel === true) {
        return redirectByKey("requiredTravelTime");
      }
      return redirectByKey("teamworkApproach");
    },
  },
  required_travel_time: {
    key: "requiredTravelTime",
    helperText: "Select from the options below",
    options: options.travelAmounts,
    question: "How much time will travel be expected?",
    variant: "radio",
    action: "updateRole",
  },
  teamwork_approach: {
    back: (p, role) => {
      if (role.requiresTravel === "true" || role.requiresTravel === true) {
        return redirectByKey("requiredTravelTime");
      }
      return "requiresTravel";
    },
    key: "teamworkApproach",
    helperText: "Select from the options below",
    options: options.teamworkStyle.slice(0, 3),
    variant: "radio",
    question:
      "Which of these teamwork styles most closely approximates the environment in which your new hire will be working?",
    action: "updateRole",
  },
  deadline_approach: {
    key: "deadlineApproach",
    helperText: "Select from the options below",
    variant: "radio",
    question:
      "How would your ideal hire respond to a deadline that was moved up (i.e. less time to complete a project)?",
    options: options.deadlineApproach,
    action: "updateRole",
  },
  level_of_structure: {
    key: "levelOfStructure",
    helperText: "Select from the options below",
    options: options.structureLevel,
    variant: "radio",
    question:
      "What is the typical management style in which this new employee will be working?",
    action: "updateRole",
  },
  more_disciplined_than_flexible: {
    key: "moreDisciplinedThanFlexible",
    variant: "preference",
    question: "How well do the following words describe your ideal new hire?",
    helperText: "Would your ideal hire be more flexible or disciplined?",
    action: "updateRole",
    leftOption: "More Flexible",
    rightOption: "More Disciplined",
  },
  more_outgoing_than_introverted: {
    key: "moreOutgoingThanIntroverted",
    variant: "preference",
    question: "How well do the following words describe your ideal new hire?",
    helperText:
      "Would your ideal hire prefer to work collaboratively with others or prefer to work/be alone ?",
    action: "updateRole",
    leftOption: "Prefers To Work Alone",
    rightOption: "Prefers Work in Social Environments",
  },
  job_file_upload: {
    key: "jobFileUpload",
    question: "Upload a Job Description",
    variant: "jobFileUpload",
  },
  undergrad_degree_requirement: {
    key: "undergradDegreeRequirement",
    isValid: (p) => p.undergradDegreeRequirement,
    question: "Does your role require an Undergraduate Degree?",
    helperText:
      "All candidates that WorkMonger recommends have a High School Diploma or GED",
    options: options.preferredBoolean,
    variant: "radio",
    action: "updateEducationPreferences",
  },
  graduate_degree_requirement: {
    key: "graduateDegreeRequirement",
    question: "Does your role require a Graduate Degree?",
    helperText:
      "If you answer YES or PREFERRED, we'll ask if you are looking for any specific ones next",
    options: options.preferredBoolean,
    variant: "radio",
    action: "updateEducationPreferences",
    next: (p, role) => {
      if (role.graduateDegreeRequirement !== "no") {
        return redirectByKey("preferredGradDegrees");
      }
      return redirectByKey("preferredAreasOfStudy");
    },
  },
  preferred_grad_degrees: {
    key: "preferredGradDegrees",
    question:
      "Which Graduate degrees are beneficial in this role from the following list?",
    closeOnSelect: true,
    transform: (r) => ({
      preferredGradDegrees: r?.preferredGradDegrees?.map(({ value }) => value),
    }),
    isValid: (p) => p?.preferredGradDegrees,
    action: "updateEducationPreferences",
    variant: "createableselect",
    options: options.graduateDegrees,
    placeholder: "Preferred Graduate Degrees",
    instructions: "Choose from the options below",
    isMulti: true,
  },
  preferred_areas_of_study: {
    key: "preferredAreasOfStudy",
    back: (p, role) => {
      if (role.graduateDegreeRequirement !== "no") {
        return redirectByKey("preferredGradDegrees");
      }
      return redirectByKey("graduateDegreeRequirement");
    },
    question:
      "Which areas of study would be valuable in this role from among the following?",
    closeOnSelect: true,
    isValid: (p) => p?.preferredGradDegrees,
    transform: (r) => ({
      preferredAreasOfStudy: r?.preferredAreasOfStudy?.map(
        ({ value }) => value
      ),
    }),
    action: "updateEducationPreferences",
    variant: "createableselect",
    options: options.areasOfStudy.map((v) => ({
      label: v,
      value: v,
    })),
    placeholder: "Preferred Areas of Study",
    instructions: "Choose from the options below",
    isMulti: true,
  },
  program_name: {
    key: "programName",
    question: "Enter program name, major or concentration:",
    maxChars: 72,
    helperText:
      "You can also enter any additional information about your educational requirements here",
    variant: "textField",
    disabledKeyboardEvents: true,
    placeholder: "Program Name, Major, or Concentration",
    action: "updateEducationPreferences",
  },
  has_college_preference: {
    key: "hasCollegePreference",
    question:
      "Do you have a preference for hiring from certain colleges or universities?",
    options: options.booleanOptions,
    helperText: "We'll ask you for which ones if you select Yes.",
    variant: "radio",
    action: "updateEducationPreferences",
    next: (p, role) => {
      if (
        role.hasCollegePreference === "true" ||
        role.hasCollegePreference === true
      ) {
        return redirectByKey("preferredColleges");
      }
      return redirectByKey("yearsFullTimeExperience");
    },
  },
  preferred_colleges: {
    key: "preferredColleges",
    variant: "searchUniversities",
    transform: (r) => ({
      preferredColleges: r?.preferredColleges?.map(({ id }) => id),
    }),
    isValid: (p) => p.preferredColleges?.length < 6,
    question: "Please select up to 5 colleges or universities:",
    helperText: "Type in the input below to see matching universities",
    action: "updateEducationPreferences",
  },
  years_full_time_experience: {
    key: "yearsFullTimeExperience",
    variant: "slider",
    back: (p, role) => {
      if (
        role.hasCollegePreference === "true" ||
        role.hasCollegePreference === true
      ) {
        return redirectByKey("preferredColleges");
      }
      return redirectByKey("hasCollegePreference");
    },
    isValid: (r) => r.yearsFullTimeExperience,
    question:
      "Please indicate the ideal number of years of full-time work experience your ideal hire would have:",
    helperText: "Use the slider below",
    action: "updateWorkExperiencePreferences",
    max: 50,
  },
  minimum_years_full_time_experience: {
    key: "minimumYearsFullTimeExperience",
    variant: "slider",
    isValid: (r) => r.minimumYearsFullTimeExperience,
    question:
      "Please indicate the minimum number of years of full-time work experience your ideal hire would have:",
    helperText: "Use the slider below",
    action: "updateWorkExperiencePreferences",
    max: 50,
  },
  requires_managing_others: {
    key: "requiresManagingOthers",
    variant: "radio",
    options: options.booleanOptions,
    action: "updateWorkExperiencePreferences",
    question: "Will this role involve managing direct reports?",
    helperText: "Select from the options below",
    isValid: (r) => isBoolean(r.requiresManagingOthers),
    next: (p, role) => {
      if (
        role.requiresManagingOthers === "true" ||
        role.requiresManagingOthers === true
      ) {
        return redirectByKey("managedNumberOfYears");
      }
      return redirectByKey("budgetResponsibility");
    },
  },
  managed_number_of_years: {
    key: "managedNumberOfYears",
    variant: "slider",
    isValid: (r) => !r.requiresManagingOthers || r.managedNumberOfYears,
    question:
      "Ideally, how many years has this person supervised other people?",
    helperText: "Use the slider below",
    action: "updateWorkExperiencePreferences",
    max: 50,
  },
  requires_managing_managers: {
    key: "requiresManagingManagers",
    variant: "radio",
    isValid: (r) =>
      !r.requiresManagingOthers || isBoolean(r.requiresManagingManagers),
    options: options.booleanOptions,
    action: "updateWorkExperiencePreferences",
    question: "Will this role involve managing managers?",
    helperText: "Select from the options below",
  },
  managed_num_of_employees: {
    key: "managedNumOfEmployees",
    variant: "radio",
    isValid: (r) => !r.requiresManagingOthers || r.managedNumOfEmployees,
    options: options.managedNumber.map((v) => ({
      label: v,
      value: v,
    })),
    action: "updateWorkExperiencePreferences",
    question:
      "How many people will report to this new hire, including both direct reports and those who their direct reports supervise?",
    helperText: "Select from the options below",
  },
  budget_responsibility: {
    key: "budgetResponsibility",
    isValid: (r) => r.budgetResponsibility,
    options: options.budgetOptions.map((v) => ({
      label: v,
      value: v,
    })),
    back: (p, role) => {
      if (
        role.requiresManagingOthers === "true" ||
        role.requiresManagingOthers === true
      ) {
        return redirectByKey("managedNumOfEmployees");
      }
      return redirectByKey("requiresManagingOthers");
    },
    variant: "radio",
    action: "updateWorkExperiencePreferences",
    question: "What is the budget management responsibility for this role?",
    helperText: "Select from the options below",
  },
  interested_in_special_education_types: {
    key: "interestedInSpecialEducationTypes",
    isValid: (r) => isBoolean(r.interestedInSpecialEducationTypes),
    options: options.booleanOptions,
    action: "updateWorkExperiencePreferences",
    variant: "radio",
    question:
      "Are you especially interested in candidates that have worked at certain types of education organizations?",
    helperText: "Select from the options below",
    next: (p, role) => {
      if (
        role.interestedInSpecialEducationTypes === "true" ||
        role.interestedInSpecialEducationTypes === true
      ) {
        return redirectByKey("specialEducationOrganizationTypes");
      }
      return redirectByKey("interestedInSpecificEmployers");
    },
  },
  special_education_organization_types: {
    key: "specialEducationOrganizationTypes",
    question: "What type of education organizations?",
    helperText: "Select multiple from the option below",
    variant: "multi",
    options: options.educationTypes.map((v) => ({ value: v, label: v })),
    action: "updateWorkExperiencePreferences",
  },
  interested_in_specific_employers: {
    key: "interestedInSpecificEmployers",
    isValid: (r) => isBoolean(r.interestedInSpecificEmployers),
    options: options.booleanOptions,
    action: "updateWorkExperiencePreferences",
    variant: "radio",
    back: (p, role) => {
      if (
        role.interestedInSpecialEducationTypes === "true" ||
        role.interestedInSpecialEducationTypes === true
      ) {
        return redirectByKey("specialEducationOrganizationTypes");
      }
      return redirectByKey("interestedInSpecialEducationTypes");
    },
    question:
      "Are you especially interested in candidates who have experience working at specific organizations?",
    helperText: "Select from the options below",
    next: (p, role) => {
      if (
        role.interestedInSpecificEmployers === "true" ||
        role.interestedInSpecificEmployers === true
      ) {
        return redirectByKey("specialEmployers");
      }
      return redirectByKey("interestedInSpecificAffiliations");
    },
  },
  special_employers: {
    key: "specialEmployers",
    action: "updateWorkExperiencePreferences",
    question:
      "Please write up to 3 employers that we should keep an eye out for:",
    variant: "textField",
    disabledKeyboardEvents: true,
    placeholder: "The New York Times, Boston Symphony Orchestra",
    helperText: "Separate your entries with a comma",
    isValid: (p) => {
      if (
        p.interestedInSpecificEmployers === "true" ||
        p.interestedInSpecificEmployers === true
      ) {
        return !!p.specialEmployers;
      }
      return true;
    },
    transform: (c) => ({
      special_employers: Array.isArray(c.specialEmployers)
        ? c.specialEmployers
        : c.specialEmployers?.split(",").map((v) => v.trim("")),
    }),
  },
  interested_in_specific_affiliations: {
    key: "interestedInSpecificAffiliations",
    isValid: (r) => isBoolean(r.interestedInSpecificAffiliations),
    options: options.booleanOptions,
    back: (p, role) => {
      if (
        role.interestedInSpecificEmployers === "true" ||
        role.interestedInSpecificEmployers === true
      ) {
        return redirectByKey("specialEmployers");
      }
      return redirectByKey("interestedInSpecificEmployers");
    },
    action: "updateWorkExperiencePreferences",
    variant: "radio",
    question:
      "Would candidates that have participated in certain fellowships or programs be strong contenders for this role?",
    helperText: "Select from the options below",
    next: (p, role) => {
      if (
        role.interestedInSpecificAffiliations === "true" ||
        role.interestedInSpecificAffiliations === true
      ) {
        return redirectByKey("specialAffiliations");
      }
      return redirectByKey("primaryFunctionalArea");
    },
  },
  special_affiliations: {
    key: "specialAffiliations",
    action: "updateWorkExperiencePreferences",
    question:
      "Please select the fellowships or programs below that would strengthen a JobSeeker’s candidacy:",
    variant: "multi",
    options: options.affiliations.map((v) => ({ value: v, label: v })),
    helperText: "Select multiple from the options below",
  },
  primary_functional_area: {
    key: "primaryFunctionalArea",
    back: (p, role) => {
      if (
        role.interestedInSpecificAffiliations === "true" ||
        role.interestedInSpecificAffiliations === true
      ) {
        return redirectByKey("specialAffiliations");
      }
      return redirectByKey("interestedInSpecificAffiliations");
    },
    action: "updateWorkExperiencePreferences",
    question:
      "Select the primary area of expertise that you are seeking in this role:",
    helperText: "We will ask for two additional ones next",
    variant: "functionalArea",
    isValid: (r) => r.primaryFunctionalArea,
    style: {
      maxHeight: "none",
    },
  },
  functional_skills_primary: {
    key: "functionalSkills1",
    relatedTo: "primaryFunctionalArea",
    variant: "functionalSkills",
    question:
      "What skills related to this area of expertise are you interested in for this role?",
  },
  secondary_functional_area: {
    key: "secondaryFunctionalArea",
    action: "updateWorkExperiencePreferences",
    question:
      "Select your second area of expertise that you are seeking in this role:",
    helperText: "We will ask for one more area next",
    variant: "functionalArea",
    isValid: (r) => r.secondaryFunctionalArea,
    style: {
      maxHeight: "none",
    },
  },
  functional_skills_secondary: {
    key: "functionalSkills2",
    relatedTo: "secondaryFunctionalArea",
    variant: "functionalSkills",
    question:
      "What skills related to this area of expertise are you interested in for this role?",
  },
  tertiary_functional_area: {
    key: "tertiaryFunctionalArea",
    action: "updateWorkExperiencePreferences",
    question:
      "Select your third area of expertise that you are seeking in this role:",
    helperText: "Select one area from the options below",
    variant: "functionalArea",
    isValid: (r) => r.tertiaryFunctionalArea,
    style: {
      maxHeight: "none",
    },
  },
  functional_skills_tertiary: {
    key: "functionalSkills3",
    relatedTo: "tertiaryFunctionalArea",
    variant: "functionalSkills",
    question:
      "What skills related to this area of expertise are you interested in for this role?",
  },
  languages: {
    key: "languages",
    question: "What languages other than English are important for this role?",
    helperText:
      "If don't require or prefer any additional questions, you can skip this question",
    action: "upsertLanguageRequirement",
    variant: "languages",
    style: {
      maxHeight: "none",
    },
  },
  skills: {
    key: "skills",
    variant: "skills",
    helperText: `Click on the gray rectangular boxes to pick the skills required by this job. Clicking on the boxes toward the right indicates that you're looking for an expert in that skill. Alternatively, clicking on a box toward the left indicates you're looking for a novice. Only worry about rating the skills you think are relevant for your role. You can add a custom skill by entering it at the bottom of each skill category.`,
    question: "What skills are important for this role?",
    style: {
      maxHeight: "none",
    },
  },
};

export default organizationQuestions;
