import React from "react";
import cn from "classnames";
import { compose, withState } from "recompose";
import withProfile from "HOC/withProfile";

import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Cancel, CheckCircle } from "@material-ui/icons";

import ProgressiveImage from "components/Image";
import ExternalLinks from "components/ExternalLinks";

import s from "./Signup.module.css";

const CompleteSignUp = ({
  newUser,
  setNewUser,
  anchorEl,
  setAnchorEl,
  history,
  updateBaseUserFields,
  profile: { user, firstName, lastName },
}) => {
  const password = newUser.password || "";
  const hasPasswordError =
    newUser.password &&
    (newUser.password.length < 9 ||
      !newUser.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{9,}$/ // eslint-disable-line
      ));

  const stages = [
    {
      complete: firstName && lastName,
      save: () => {
        updateBaseUserFields({
          userId: user,
          updates: {
            firstName: newUser.firstName,
            lastName: newUser.lastName,
          },
          doUpdateProfile: true,
        });
      },
      buttonText: "Continue",
      enabled: newUser.firstName && newUser.lastName,
      component: (
        <>
          <div className={s.gettingStarted}>Nice to meet you!</div>
          <TextField
            fullWidth
            InputProps={{
              classes: {
                root: s.input,
              },
              autoComplete: "off",
            }}
            color="secondary"
            label="First Name"
            value={newUser.firstName}
            onChange={(e) =>
              setNewUser({
                ...newUser,
                firstName: e.target.value,
              })
            }
          />

          <TextField
            fullWidth
            InputProps={{
              classes: {
                root: s.input,
              },
              autoComplete: "off",
            }}
            color="secondary"
            label="Last Name"
            value={newUser.lastName}
            onChange={(e) =>
              setNewUser({
                ...newUser,
                lastName: e.target.value,
              })
            }
          />
        </>
      ),
    },
    {
      // The user wouldn't end up here
      // if the password were already set
      complete: false,
      save: () => {
        updateBaseUserFields({
          userId: user,
          updates: {
            password: newUser.password,
          },
          doUpdateProfile: true,
        });
        history.push("/");
      },
      buttonText: "Get Started Now",
      enabled:
        newUser.password &&
        newUser.passwordMatch &&
        newUser.password === newUser.passwordMatch &&
        newUser.password.length >= 9,
      component: (
        <>
          <div className={s.gettingStarted}>
            {firstName || newUser.firstName}, let’s set a password so you can
            login anytime to update your career preferences and requirements.
          </div>

          <div className={s.passwordInstructions}>
            Make sure that your password
            <ul className={s.passwordList}>
              <li
                className={cn(s.notChecked, {
                  [s.checked]: password.length >= 9,
                })}
              >
                {password.length >= 9 ? <CheckCircle /> : <Cancel />} Is at
                least 9 characters long
              </li>
              <li
                className={cn(s.notChecked, {
                  [s.checked]: /[A-Z]/.test(password),
                })}
              >
                {/[A-Z]/.test(password) ? <CheckCircle /> : <Cancel />} Contains
                at least 1 uppercase letter
              </li>
              <li
                className={cn(s.notChecked, {
                  [s.checked]: /[a-z]/.test(password),
                })}
              >
                {/[a-z]/.test(password) ? <CheckCircle /> : <Cancel />} Contains
                at least 1 lowercase letter
              </li>
              <li
                className={cn(s.notChecked, {
                  [s.checked]: /\d/.test(password),
                })}
              >
                {/\d/.test(password) ? <CheckCircle /> : <Cancel />} Contains at
                least 1 number
              </li>
            </ul>
          </div>

          <TextField
            fullWidth
            InputProps={{
              classes: {
                root: s.input,
              },
            }}
            color="secondary"
            autoComplete="new-password"
            onFocus={(e) => {
              if (!anchorEl) {
                setAnchorEl(e.currentTarget);
              }
            }}
            autoFocus={false}
            onBlur={() => setAnchorEl(null)}
            error={hasPasswordError}
            type="password"
            label="Password"
            value={newUser.password}
            onChange={(e) =>
              setNewUser({
                ...newUser,
                password: e.target.value,
              })
            }
          />
          <TextField
            fullWidth
            InputProps={{
              classes: {
                root: s.input,
              },
            }}
            color="secondary"
            autoComplete="off"
            error={
              newUser.passwordMatch &&
              newUser.passwordMatch !== newUser.password
            }
            label={
              newUser.passwordMatch &&
              newUser.passwordMatch !== newUser.password
                ? "Passwords must match"
                : "Confirm Password"
            }
            onFocus={(e) => {
              if (!anchorEl) {
                setAnchorEl(e.currentTarget);
              }
            }}
            onBlur={() => setAnchorEl(null)}
            type="password"
            value={newUser.passwordMatch}
            onChange={(e) =>
              setNewUser({
                ...newUser,
                passwordMatch: e.target.value,
              })
            }
          />
        </>
      ),
    },
  ];

  const activeStage = stages.find((s) => !s.complete);

  return (
    <form>
      <div className={s.container}>
        <ExternalLinks />
        <div className={s.backgroundImage}>
          <Paper className={s.paper}>
            <div className={s.headerArea}>
              <ProgressiveImage
                alt="workmonger logo"
                src="https://s3.amazonaws.com/workmonger-logos/keyIcon-min.png"
                className={s.img}
              />
              <div className={s.title}>
                <div className={s.name}>WorkMonger Career Platform</div>
              </div>
            </div>
            <Divider className={s.divider} />
            <div className={s.signUpArea}>
              {activeStage.component}
              {activeStage.buttonText ? (
                <Button
                  classes={{
                    root: s.createAccount,
                    label: s.buttonText,
                  }}
                  type="submit"
                  disabled={!activeStage.enabled}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    activeStage.save();
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  {activeStage.buttonText}
                </Button>
              ) : null}
            </div>
          </Paper>
        </div>
      </div>
    </form>
  );
};

export default compose(
  withState("anchorEl", "setAnchorEl", null),
  withProfile
)(CompleteSignUp);
