import React, { useEffect, lazy, Suspense } from "react";
import { Redirect, Router, Switch, Route } from "react-router-dom";

import withProfile from "HOC/withProfile";
import history from "utils/history";
import initGoogleAnalytics from "utils/GoogleAnalytics";

import WithTracker from "hooks/WithTracker";
import LoadingOverlay from "components/LoadingOverlay";
import ProtectedRoute from "containers/ProtectedRoute";
import LandingPage from "containers/LandingPage";
import CompleteSignUp from "containers/CompleteSignUp";
import MinimalAppbar from "containers/MinimalAppbar";

const ClaimTypeformAccount = lazy(() =>
  import("containers/ClaimTypeformAccount")
);
const EmployerHome = lazy(() => import("pages/EmployerHome"));
const ConfirmEmail = lazy(() => import("containers/ConfirmEmail"));
const StaffLanding = lazy(() => import("pages/StaffLanding"));
const Login = lazy(() => import("../containers/LoginContainer"));
const ModalContainer = lazy(() => import("../containers/ModalContainer"));
const ProfileCompletion = lazy(() => import("../containers/ProfileCompletion"));
const ResetPassword = lazy(() => import("../containers/ResetPassword"));
const Signup = lazy(() => import("../containers/Signup"));
const Logout = lazy(() => import("../containers/Logout"));
const Settings = lazy(() => import("containers/Settings"));
const RedirectWithParams = lazy(() => import("../containers/Redirect"));
const EmployerProfile = lazy(() => import("pages/EmployerProfile"));
const EmployerOverview = lazy(() => import("pages/EmployerOverview"));
const EmployeeBasicInfo = lazy(() => import("pages/EmployeeBasicInfo"));
const JobSeekerDashboard = lazy(() => import("pages/JobSeekerDashboard"));
const StaffMatchingDashboard = lazy(() =>
  import("pages/StaffMatchingDashboard")
);
const RoleFilterSetConfiguration = lazy(() =>
  import("pages/RoleFilterSetConfiguration")
);
const MatchesListView = lazy(() => import("pages/MatchesListView"));
const EmployerMatchingDashboard = lazy(() =>
  import("pages/EmployerMatchingDashboard")
);
// const Deploying = lazy(() => import("../components/Deploying"));

/**
 * If deploying, uncomment both lines:
 * const Deploying = lazy(() => import('../components/Deploying'));
 * <Route component={Deploying} />
 */
const Routes = ({ profile, dirty, expiresAt, token }) => {
  const { user, jobTitle } = profile || {};
  const { userType } = token || {};

  useEffect(() => initGoogleAnalytics(user), []);

  const isLoggedIn = expiresAt && expiresAt !== "expired";
  const interceptEmployeeDetails = isLoggedIn && user && !jobTitle;

  return (
    <Router history={history}>
      <WithTracker />
      <Suspense fallback={<LoadingOverlay />}>
        <Switch>
          {/* Authentication Routes */}
          {/* <Route component={Deploying} /> */}
          <Route path="/login" component={Login} />
          <Route
            path="/employer/signup"
            render={() => <Signup isEmployer={true} />}
          />
          <Route
            path="/jobseeker/signup"
            render={() => <Signup isJobSeeker={true} />}
          />
          <Route path="/signup" component={Signup} />
          <Route path="/logout" component={Logout} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/claim-your-account" component={ClaimTypeformAccount} />
          <Route path="/redirect" component={RedirectWithParams} />
          <Route path="/confirm_email" component={ConfirmEmail} />
          <Route path="/loading" component={LoadingOverlay} />
          <Route
            key="/complete-sign-up"
            path="/complete-sign-up"
            component={CompleteSignUp}
          />
          <Route
            key="employer-public-page"
            path="/employer/:employerName"
            component={EmployerOverview}
          />
          {userType === "employee" ? (
            <MinimalAppbar>
              <Switch>
                <ProtectedRoute
                  path={interceptEmployeeDetails ? "" : "/employee/details"}
                  key="employee-details"
                  title="My Profile"
                  component={EmployeeBasicInfo}
                />
                <ProtectedRoute
                  key="role-details"
                  path="/role/:roleUuid/:questionName?"
                  title="Employer Profile"
                  component={EmployerProfile}
                />
                <ProtectedRoute
                  key="settings"
                  path="/settings"
                  title="Settings"
                  component={Settings}
                />
                <ProtectedRoute
                  key="employer-profile-questions"
                  path="/profile/:questionName"
                  title="Company Profile"
                  component={EmployerProfile}
                />
                <ProtectedRoute
                  key="employer-dashboard-explicit"
                  path="/dashboard"
                  title="Dashboard"
                  component={EmployerHome}
                />
                <ProtectedRoute
                  key="staff-matching"
                  path="/matching/:activeRole?"
                  title="Matches"
                  component={EmployerMatchingDashboard}
                />
                <Redirect key="employer-redirect" to="/dashboard" />
              </Switch>
            </MinimalAppbar>
          ) : null}
          {isLoggedIn && userType === "jobseeker" ? (
            <MinimalAppbar>
              <Switch>
                <ProtectedRoute
                  key="settings"
                  path="/settings"
                  title="Settings"
                  component={Settings}
                />
                <ProtectedRoute
                  key="profile-questions"
                  path="/profile/:questionName"
                  title="My Profile"
                  component={ProfileCompletion}
                />
                <ProtectedRoute
                  key="jobseeker-dashbaord"
                  path="/dashboard"
                  title="Dashboard"
                  component={ProfileCompletion}
                />
                <ProtectedRoute
                  title="Matches"
                  path="/matching"
                  component={JobSeekerDashboard}
                />
                <ProtectedRoute
                  title="Matches"
                  path="/v2/dashboard"
                  component={JobSeekerDashboard}
                />
                <Redirect key="redirect" path="/profile" to="/" />
                <Route key="fallback" path="*" component={LandingPage} />
              </Switch>
            </MinimalAppbar>
          ) : null}
          {userType === "staff" ? (
            <MinimalAppbar>
              <Switch>
                <ProtectedRoute
                  key="staff-settings"
                  path="/settings"
                  title="Settings"
                  component={Settings}
                />
                <ProtectedRoute
                  key="staff-landing"
                  path="/dashboard/:listName?"
                  title="Dashboard"
                  component={StaffLanding}
                />
                <ProtectedRoute
                  key="staff-matching-dashboard"
                  path="/v2/dashboard"
                  title="Matching"
                  component={StaffMatchingDashboard}
                />
                <ProtectedRoute
                  key="staff-matching"
                  path="/matching"
                  title="Matching"
                  component={StaffMatchingDashboard}
                />
                <ProtectedRoute
                  key="role-filterset"
                  path="/role/filterset/:roleUuid?"
                  title="Role Queryset"
                  component={RoleFilterSetConfiguration}
                />
                <ProtectedRoute
                  key="role-filterset"
                  path="/role/matches/:roleUuid?"
                  title="Matches"
                  component={MatchesListView}
                />
                <Redirect key="staff-redirect" to="/dashboard" />
              </Switch>
            </MinimalAppbar>
          ) : null}
          <Route key="fallback" path="*" component={LandingPage} />
        </Switch>
        <Route component={ModalContainer} />
      </Suspense>
    </Router>
  );
};

export default withProfile(Routes);
