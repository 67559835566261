/**
 * This is a wrapper around a route
 * that determines if a user is logged in.
 * This can be expanded to include
 * multiple user types checking.
 */

import React, { Component } from 'react';
import compose from 'HOC/compose';
import pick from 'ramda/src/pick';

import { DateTime } from 'luxon';
import { Redirect } from 'react-router-dom';
import { dotPath } from 'utils/cookbook';
import { connect } from 'react-redux';

class Protected extends Component {
  render() {
    const { user, children } = this.props;
    const exp = dotPath('token.exp', user) || 0;
    const isLoggedIn = DateTime.local() < DateTime.fromSeconds(exp);
    return isLoggedIn ? children : <Redirect to="/login" />;
  }
}

export default compose(
  connect(
    pick(['user']),
    null,
  ),
)(Protected);
