import React, { useEffect, useState } from "react";
import { useUser, simpleFetch, useIsCompanyProfileComplete } from "hooks";
import { useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation, Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import cn from "classnames";

import { revertPersonification } from "actions/profile";

import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dashboard from "@material-ui/icons/Dashboard";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Settings from "@material-ui/icons/Settings";
import GroupAddRounded from "@material-ui/icons/GroupAddRounded";
import LocalHospital from "@material-ui/icons/LocalHospital";
import Dangerous from "@material-ui/icons/CancelPresentation";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import ProgressiveImage from "components/Image";
import RoleProgressPopover from "containers/RoleProgressPopover";
import Appbar from "components/AppBar";

import s from "./MinimalAppbar.module.css";

export default function MinimalAppbar({ children }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [matchCount, setMatchCount] = useState(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isNearlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isLoggedIn, isJobSeeker, isEmployee, isStaff, profile } = useUser();
  const isPersonifyingEmployer = isEmployee && profile?.isStaff;

  useEffect(async () => {
    if (isEmployee || isJobSeeker) {
      const fetchMatchCount = async () => {
        const matchCountUrl = `/api/employer/new-match-count/`;
        const {
          data: { count },
        } = await simpleFetch(matchCountUrl);
        setMatchCount(count);
      };
      fetchMatchCount();
    }
  }, [isJobSeeker, isEmployee]);

  const linkTo = (to) => {
    history.push(to);
    setMobileDrawerOpen(false);
  };
  const [isCompanyProfileComplete] = useIsCompanyProfileComplete();
  const canViewDashboard = () => {
    if (isJobSeeker) {
      return profile.completedSections.includes("core");
    } else if (isEmployee) {
      return isCompanyProfileComplete;
    }
    return true;
  };

  const renderLoginButton = () => (
    <div className={s.loginContainer}>
      <Button component={Link} to="/login" className={s.loginButton}>
        Login
      </Button>
    </div>
  );

  const renderEmployeeBadge = () => {
    if (!isEmployee || isNearlyMobile) {
      return <div />;
    }

    if (pathname?.includes("/role/")) {
      return <RoleProgressPopover />;
    }

    return (
      <div className={s.employerBadge}>
        <ProgressiveImage
          className={s.employerLogo}
          src={
            profile?.logo ||
            "https://workmonger-logos.s3.amazonaws.com/company-placeholder.png"
          }
          alt="employer logo"
        />
        <div className={s.employerName}>{profile?.name}</div>
      </div>
    );
  };

  const canAccessDashboard = !!canViewDashboard();

  const renderLoggedInRightSection = () => {
    if (isMobile) {
      return (
        <>
          <div className={s.appbarContent}>
            <div />
            <div className={s.rightAppbar}>
              <MenuIcon onClick={() => setMobileDrawerOpen(true)} />
            </div>
          </div>
          <Drawer
            anchor="bottom"
            open={mobileDrawerOpen}
            onClose={() => setMobileDrawerOpen(false)}
            className={s.drawer}
            classes={{
              paper: s.drawer,
              paperAnchorBottom: s.drawer,
            }}
          >
            {canAccessDashboard && (
              <div
                onClick={() => linkTo("/dashboard")}
                className={s.mobileLink}
              >
                <Dashboard />
                <div>Dashboard</div>
              </div>
            )}
            {canAccessDashboard && (
              <div onClick={() => linkTo("/matching")} className={s.mobileLink}>
                <Badge
                  badgeContent={matchCount ? `${matchCount} New` : null}
                  color="error"
                  classes={{
                    badge: s.badgeNew,
                  }}
                >
                  <GroupAddRounded />
                </Badge>
                <div>Matches</div>
              </div>
            )}
            {isPersonifyingEmployer ? (
              <div>
                <div
                  role="button"
                  onClick={() => revertPersonification()}
                  className={s.mobileLink}
                >
                  <Dangerous />
                  <div>Go Back to Staff Account</div>
                </div>
              </div>
            ) : null}
            <div onClick={() => linkTo("/settings")} className={s.mobileLink}>
              <Settings />
              <div>Settings</div>
            </div>
            {!isStaff && (
              <div onClick={window.FreshWidget.show} className={s.mobileLink}>
                <LocalHospital />
                <div>Support</div>
              </div>
            )}
            <div onClick={() => linkTo("/logout")} className={s.mobileLink}>
              <ExitToApp />
              <div>Log Out</div>
            </div>
          </Drawer>
        </>
      );
    }

    return (
      <div className={s.appbarContent}>
        {renderEmployeeBadge()}
        <div className={s.rightAppbar}>
          {canAccessDashboard && (
            <div
              onClick={() => linkTo("/dashboard")}
              className={cn(s.menuItem, {
                [s.active]:
                  pathname.includes("dashboard") && !pathname.includes("v2"),
              })}
            >
              <Dashboard />
              <div>Dashboard</div>
            </div>
          )}
          {canAccessDashboard && (
            <div
              onClick={() => linkTo("/matching")}
              className={cn(s.menuItem, {
                [s.active]: pathname.includes("matching"),
              })}
            >
              <Badge
                badgeContent={matchCount ? `${matchCount} New` : null}
                color="error"
                classes={{
                  badge: s.badgeNew,
                }}
              >
                <GroupAddRounded />
              </Badge>
              <div>Matches</div>
            </div>
          )}
          <div
            onClick={() => linkTo("/settings")}
            className={cn(s.menuItem, {
              [s.active]: pathname.includes("settings"),
            })}
          >
            <Settings />
            <div>Settings</div>
          </div>
          {!isStaff ? (
            <div onClick={window.FreshWidget.show} className={s.menuItem}>
              <LocalHospital />
              <div>Support</div>
            </div>
          ) : null}
          <div onClick={() => linkTo("/logout")} className={s.menuItem}>
            <ExitToApp />
            <div>Log Out</div>
          </div>
          {isPersonifyingEmployer ? (
            <div>
              <div
                role="button"
                onClick={() => revertPersonification()}
                className={cn(s.menuItem, s.exitStaffAccount)}
              >
                <div>Back to Staff Account</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <>
      <Appbar
        right={() =>
          isLoggedIn ? renderLoggedInRightSection() : renderLoginButton()
        }
      />
      <div className={s.content}>{children}</div>
    </>
  );
}
