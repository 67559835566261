import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import cn from "classnames";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ProgressiveImage from "components/Image";

import s from "./AppBar.module.css";

const TopBar = ({ color, right = () => null }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <AppBar
      style={{
        backgroundColor: color,
      }}
      className={s.appBar}
    >
      <Toolbar>
        <ProgressiveImage
          width={64}
          height={64}
          className={cn(s.logo, {
            [s.mobileLogo]: isMobile,
          })}
          src={
            isMobile
              ? "https://workmonger-logos.s3.amazonaws.com/icon.png"
              : "https://s3.amazonaws.com/workmonger-logos/keyIcon-min.png"
          }
          alt="WorkMonger Logo"
        />
        {right()}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
