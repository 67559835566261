import React from "react";
import { Route } from "react-router-dom";
import Protected from "containers/Protected";
import { Helmet } from "react-helmet";

const ProtectedRoute = ({ children, title, ...props }) => (
  <Protected>
    <Helmet>
      <title>{title ? `${title} | WorkMonger` : "WorkMonger"}</title>
    </Helmet>
    <Route {...props}>{children}</Route>
  </Protected>
);

export default ProtectedRoute;
