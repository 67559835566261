export default {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#2c3d49",
      light: "#6b777f",
      dark: "#1a242b",
      contrastText: "#fff",
    },
    secondary: {
      main: "#2193b0",
      light: "#79BECF",
      dark: "#104958",
      contrastText: "#fff",
    },
    error: {
      active: "#ef5350",
      main: "#ef5350",
      light: "#ef5350",
      dark: "#ef5350",
      contrastText: "#fff",
      hover: "#ef5350",
      selected: "#ef5350",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: 6,
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        position: "sticky",
        top: 14,
        left: 32,
        right: 32,
        borderTop: "2px solid #199dd6",
        borderBottom: "2px solid #199dd6",
        zIndex: 10000,
        borderRadius: 0,
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `2px solid #2193b0`,
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
};
