export const roleTypes = [
  {
    value: "full-time",
    label: "Full-Time (40+ hours/week)",
  },
  {
    value: "part-time (30 - 40 hours / week)",
    label: "Part-Time",
    helperText: "about 30 to 40 hours per week",
  },
  {
    value: "part-time (20 - 30 hours / week)",
    label: "Part-Time",
    helperText: "about 20 to 30 hours per week",
  },
  {
    value: "part-time (< 20 hours / week)",
    label: "Part-Time",
    helperText: "less than 20 hours per week",
  },
  {
    value: "contracted",
    label: "Contractor (paid by completed project)",
  },
];

export const educationTypes = [
  "Charter School / Charter Management Organization",
  "Public School District",
  "Private School, Non-Religious",
  "Private School, Faith-Based",
  "State Education Agency",
  "Non-Profit Organization",
  "Consulting / Services",
  "Education Technology",
  "Higher Education",
  "Foundation",
  "Other",
];

export const companySize = [
  "1 - 10 employees",
  "11 - 50 employees",
  "51 - 100 employees",
  "101 - 500 employees",
  "500+ employees",
];

export const managedNumber = [
  "1-2 people",
  "3-5 people",
  "6-10 people",
  "11-20 people",
  "21-40 people",
  "41-75 people",
  "76-100 people",
  "100+ people",
];

export const referralTypes = [
  "Email from WorkMonger",
  "Internet Search",
  "Google Ad",
  "Friend",
  "Campus Recruiting",
  "Job Board",
  "Facebook",
  "LinkedIn",
  "Twitter",
  "Other",
];

export const personalityTraits = [
  "Fast-paced",
  "Results-driven",
  "Flexible",
  "Systems-oriented",
  "Predictable",
  "Growth-oriented",
  "Fun",
  "Friendly",
  "Process-oriented",
  "Flat organization structure",
  "Metrics-focused",
  "Structured",
  "Innovative",
  "Goals",
  "Ambiguous",
  "Customer-oriented",
  "Cordial",
  "Driven for excellence",
  "Hierarchy",
];

export const growthStages = [
  "Start-up",
  "Early Growth",
  "Established",
  "Expansion",
  "Mature",
];

export const employeeRelationshipTraits = [
  {
    text: "Employees have close personal relationships with each other",
    value: "employeesHaveCloseRelationships",
  },
  {
    text: "My organization provides strong opportunity for professional development",
    value: "hasPotentialForHighGrowth",
  },
  {
    text: "My organization provides strong opportunity for advancement within the organization",
    value: "hasOpportunityForAdvancement",
  },
  {
    text: "My organization offers great work / life balance",
    value: "hasWorkLifeBalance",
  },
];

export const roleLengths = [
  {
    label: "Ongoing",
    value: "ongoing",
  },
  {
    label: "About 1 - 3 months (Seasonal)",
    value: "seasonal",
  },
  {
    label: "About 3 - 6 months",
    value: "3 - 6 months",
  },
  {
    label: "About 6 - 12 months",
    value: "6 - 12 months",
  },
];

export const roleLocationType = [
  { value: "in person", label: "In-person" },
  { value: "remote", label: "Remote" },
  { value: "mix", label: "Mix of In-person and Remote" },
];

export const geographicRequirement = [
  {
    value: "home",
    label:
      "This candidate must be located in the same metro area as our headquarters.",
  },
  {
    value: "specific",
    label: "This candidate must be located in one or more specific metro areas",
  },
  {
    value: "anywhere",
    label:
      "For the right candidate, I would be willing to have them live anywhere in the United States",
  },
];

export const affiliations = [
  "Military Veteran",
  "AmeriCorps",
  "Broad Residency",
  "Citizen Schools",
  "City Year",
  "Echoing Green",
  "Education Pioneers",
  "Management Leaders of Tomorrow",
  "Peace Corps",
  "POSSE Foundation",
  "ProInspire",
  "Teach For America",
  "TNTP",
];

export const booleanOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const preferredBoolean = [
  { value: "yes", label: "Yes" },
  { value: "preferred", label: "Preferred" },
  { value: "no", label: "No" },
];

export const travelAmounts = [
  { value: "< 10%", label: "Less than 10% of the time" },
  { value: "10 - 25%", label: "About 10 to 25% of the time" },
  { value: "25 - 50%", label: "About 25 to 50% of the time" },
  { value: "50%+", label: "More than 50% of the time" },
];

export const teamworkStyle = [
  {
    value: "Divying Up",
    label: "Divvying up the shared responsibilities",
    helperText:
      " and each person working individually on their piece of the collective project/goal",
  },
  {
    value: "Ownership",
    label: "Divvying up the shared responsibilities so that each portion",
    helperText:
      "has a specific owner; that owner taps others on the team for input, assistance, and work as necessary",
  },
  {
    value: "Shared",
    label: "All members work together on all aspects of the project/goal,",
    helperText:
      "ensuring maximum team member input and contribution to the project",
  },
  {
    value: "Indifferent",
    label: "I am indifferent between any of these teamwork structures",
  },
  {
    value: "Individual",
    label:
      "In all situations, I prefer to work independently and not on a team",
  },
];

export const structureLevel = [
  {
    value: "Structured",
    label: "Highly Structured",
    helperText:
      "Frequent scheduled calls/meetings with manager, regular/interim deadlines",
  },
  {
    value: "Somewhat Structured",
    label: "Somewhat structured",
    helperText:
      "Mixture of independent working and structured check-ins and deadlines",
  },
  {
    value: "Loosely Structured",
    label: "Unstructured",
    helperText:
      "Highly autonomous schedule, independent working, infrequent interaction with manager",
  },
];

export const highestEducationLevelOptions = [
  {
    label: "High School Diploma or GED",
    value: "High School Diploma / GED",
  },
  { label: "Undergraduate", value: "Undergraduate" },
  { label: "Graduate", value: "Graduate" },
  { label: "None of these options apply", value: "N/A" },
];

export const graduateDegrees = [
  {
    value: "Ed.M.",
    label: "Master of Education (Ed.M.)",
  },
  {
    value: "M.A.",
    label: "Master of the Arts (M.A.)",
  },
  {
    value: "M.A.T.",
    label: "Master of Arts in Teaching (M.A.T.)",
  },
  {
    value: "M.B.A.",
    label: "Master of Business Administration (M.B.A.)",
  },
  {
    value: "M.Ed.",
    label: "Master of Education (M.Ed.)",
  },
  {
    value: "M.P.A",
    label: "Master of Public Administration (M.P.A.)",
  },
  {
    value: "M.P.H.",
    label: "Master of Public Health (M.P.H.)",
  },
  {
    value: "M.P.P.",
    label: "Master of Public Policy (M.P.P)",
  },
  {
    value: "M.S.",
    label: "Master of Science (M.S.)",
  },
  {
    value: "M.S.Ed.",
    label: "Master of Science Education (M.S.Ed.)",
  },
  {
    value: "M.S.W.",
    label: "Master of Social Work (M.S.W.)",
  },
  {
    value: "M.Sc.",
    label: "Master of Science (M.Sc.)",
  },
  {
    value: "J.D.",
    label: "Juris Doctor (J.D.)",
  },
  {
    value: "Ed.D.",
    label: "Doctor of Education (Ed.D.)",
  },
  {
    value: "Ed.L.D.",
    label: "Doctor of Education Leadership (Ed.L.D)",
  },
  {
    value: "Ph.D.",
    label: "Doctor of Philosophy (Ph.D.)",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const gradDegreeLabelMap = {
  "Ed.M.": "Master of Education (Ed.M.)",
  "M.A.": "Master of the Arts (M.A.)",
  "M.A.T.": "Master of Arts in Teaching (M.A.T.)",
  "M.B.A.": "Master of Business Administration (M.B.A.)",
  "M.Ed.": "Master of Education (M.Ed.)",
  "M.P.A": "Master of Public Administration (M.P.A.)",
  "M.P.H.": "Master of Public Health (M.P.H.)",
  "M.P.P.": "Master of Public Policy (M.P.P)",
  "M.S.": "Master of Science (M.S.)",
  "M.S.Ed.": "Master of Science Education (M.S.Ed.)",
  "M.S.W.": "Master of Social Work (M.S.W.)",
  "M.Sc.": "Master of Science (M.Sc.)",
  "J.D.": "Juris Doctor (J.D.)",
  "Ed.D.": "Doctor of Education (Ed.D.)",
  "Ed.L.D.": "Doctor of Education Leadership (Ed.L.D)",
  "Ph.D.": "Doctor of Philosophy (Ph.D.)",
  Other: "Other",
};

export const areasOfStudy = [
  "Agriculture",
  "Anthropology",
  "Archaeology",
  "Architecture and Design",
  "Business",
  "Chemistry",
  "Computer sciences",
  "Cultural and ethnic studies",
  "Divinity",
  "Earth sciences",
  "Economics",
  "Education",
  "Engineering",
  "English Language",
  "Environmental studies and Forestry",
  "Family and consumer science",
  "Gender and sexuality studies",
  "Geography",
  "Healthcare science",
  "History",
  "Human physical performance and recreation",
  "Journalism, media studies and communication",
  "Law",
  "Library and museum studies",
  "Life sciences",
  "Linguistics",
  "Literature",
  "Mathematics",
  "Military sciences",
  "Performing arts",
  "Philosophy",
  "Physics",
  "Political science",
  "Psychology",
  "Public administration",
  "Religion",
  "Social work",
  "Sociology",
  "Space sciences",
  "Statistics",
  "Systems science",
  "Transportation",
  "Visual arts",
  "Other",
];

export const budgetOptions = [
  "No Budget Management Responsibility",
  "Less than $100k",
  "$100k - $250k",
  "$250k - $500k",
  "$500k - $999k",
  "$1m - $2.99m",
  "$3m - $4.99m",
  "5m - $9.99m",
  "$10m - $19.99m",
  "$20m - $29.99m",
  "$30m - $39.99m",
  "$40m - $49.99m",
  "$50m - $99.99m",
  "$100 million or more",
];

export const deadlineApproach = [
  {
    value: "Push Back",
    label: "Push back on the new deadline",
    helperText:
      "and ask to meet the original deadline so that the quality of work does not suffer.",
  },
  {
    value: "Compromise",
    label: "Try to meet the new deadline if possible",
    helperText:
      "but feel ok only meeting original deadline (as long as the fact that the new deadline was not going to be met was communicated timely).",
  },
  {
    value: "Work To Meet",
    label: "Work longer hours",
    helperText: "to meet the new deadline at all costs.",
  },
];

export const roleBenefits = [
  {
    value: "health insurance",
    label: "Health Insurance",
  },
  {
    value: "dental insurance",
    label: "Dental Insurance",
  },
  {
    value: "vision insurance",
    label: "Vision Insurance",
  },
  {
    value: "gym or health reimbursement",
    label: "Gym or Health Reimbursement",
  },
  {
    value: "life insurance",
    label: "Life Insurance",
  },
  {
    value: "retirement plan",
    label: "Retirement Plan",
  },
  {
    value: "stock options",
    label: "Stock Options",
  },
  {
    value: "performance bonus",
    label: "Performance Bonus",
  },
  {
    value: "maternity and paternity leave",
    label: "Maternity and Paternity Leave",
  },
  {
    value: "flexible hours",
    label: "Flexible Hours",
  },
  {
    value: "paid time off",
    label: "Paid Time Off",
  },
  {
    value: "paid holidays",
    label: "Paid Holidays",
  },
  {
    value: "sick days",
    label: "Sick Days",
  },
  {
    value: "professional development",
    label: "Professional Development",
  },
];

export const rejectMatchOptions = [
  {
    value: "mismatch in location preferences",
    label: "I’m not interested in this location",
  },
  {
    value: "no alignment with interests",
    label: "This role doesn’t match with my career alignment",
  },
  {
    value: "mismatch in role level",
    label: "The level of the role is not what I’m looking for",
  },
  {
    value: "accepted another offer",
    label: "I just found a new job",
  },
  {
    value: "staying with current employer",
    label: "I'm staying with my current employer",
  },
  {
    value: "starting grad school",
    label: "I'm starting grad school!",
  },
  {
    value: "compensation is too low",
    label:
      "The compensation offered by the role is lower than my minimum requirement",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const employerRejectMatchOptions = [
  {
    value: "candidate missing relevant experience",
    label: "Candidate is missing relevant experience",
  },
  {
    value: "mismatch in compensation expectations",
    label: "There's a mismatch in compensation expectations",
  },
  {
    value: "mismatch in location preferences",
    label: "There's a mismatch in location preferences",
  },
  {
    value: "overqualified",
    label: "This candidate is overqualified",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const seekingStatusOptions = [
  { value: "active", label: "I am actively seeking my next job" },
  {
    value: "passive",
    label:
      "I am currently employed and happy, but if a great job came my way, I’d consider it",
  },
  {
    value: "part-time",
    label:
      "I am currently employed and am seeking a job to do in the evenings or on the weekends",
  },
  {
    value: "curious",
    label:
      "I’m not in a position to take a new job right now, but I’m curious what’s out there for down the road",
  },
];

export const matchEvents = [
  "confirm match",
  "staff rejected",
  "jobseeker accept",
  "jobseeker reject",
  "pending employer review",
  "match expired",
  "pending feedback or next step",
  "resume review",
  "phone screen",
  "confirm interview slot",
  "initial interview",
  "performance task",
  "second round interview",
  "team interview",
  "task presentation",
  "final interview",
  "reference check",
  "employer accept",
  "employer reject",
  "offer extended",
  "offer rejected",
  "hired",
];

const status = {
  STATUS: "status",
  BOOLEAN: "boolean",
};

export const matchEventsConfiguration = [
  {
    event: "confirm match",
    category: status.BOOLEAN,
    name: "staff has confirmed match",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    staffOnly: true,
    autoarchive: false,
    description:
      "Confirm the match and the JobSeeker will be alerted in an email in the morning",
  },
  {
    event: "staff rejected",
    category: status.STATUS,
    name: "staff has rejected the match",
    responsibleParty: null,
    note: "",
    singleUserOnly: false,
    staffOnly: true,
    autoarchive: true,
    description:
      "Indicates that a particular JobSeeker is not a good fit for this role",
  },
  {
    event: "jobseeker accept",
    category: status.STATUS,
    name: "JobSeeker has accepted",
    responsibleParty: "staff review",
    note: "",
    singleUserOnly: false,
    staffOnly: true,
    autoarchive: false,
    description:
      "Indicates that the JobSeeker has shown interested in the job. The next step is adding a note before it is introduced to the employer",
  },
  {
    event: "jobseeker reject",
    category: status.STATUS,
    name: "Turned down role",
    responsibleParty: null,
    note: "",
    singleUserOnly: false,
    staffOnly: true,
    autoarchive: true,
    description:
      "Indicates that the JobSeeker was no longer interested in the role",
  },
  {
    event: "pending employer review",
    category: status.STATUS,
    name: "Pending Employer Review",
    responsibleParty: "employer",
    note: "",
    singleUserOnly: false,
    staffOnly: true,
    autoarchive: false,
    description:
      "Indicates that a note has been added for introducing the JobSeeker to the employer",
  },
  {
    event: "match expired",
    category: status.STATUS,
    name: "This role has been closed",
    responsibleParty: null,
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    autoarchive: true,
    description:
      "Indicates that the match has been closed and the match is no longer relevant",
  },
  {
    event: "pending feedback or next step",
    category: status.STATUS,
    name: "Pending Feedback",
    responsibleParty: "",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    autoarchive: false,
    description: "This is a generic holding stage if the next step is unknown.",
  },
  {
    event: "resume review",
    category: status.STATUS,
    name: "Resume Review",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    autoarchive: false,
    staffOnly: false,
    description:
      "Indicates that the employer is reviewing the JobSeeker's resume",
  },
  {
    event: "offer extended",
    category: status.STATUS,
    name: "Offer Extended",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: true,
    autoarchive: false,
    staffOnly: false,
    description: "A JobSeeker has been extended an offer for the role",
  },
  {
    event: "offer rejected",
    category: status.STATUS,
    name: "Offer Rejected",
    responsibleParty: "jobseeker review",
    note: "",
    autoarchive: true,
    singleUserOnly: false,
    staffOnly: false,
    description: "A JobSeeker has been rejected an offer for the role",
  },
  {
    event: "phone screen",
    category: status.STATUS,
    name: "Phone Screen",
    responsibleParty: "jobseeker review",
    note: "",
    autoarchive: false,
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer is setting up a phone screen",
  },
  {
    event: "initial interview",
    category: status.STATUS,
    name: "Initial Interview",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    autoarchive: false,
    description: "The employer is setting up an initial interview",
  },
  {
    event: "performance task",
    category: status.STATUS,
    autoarchive: false,
    name: "Performance Task",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer is setting up a performance task interview",
  },
  {
    event: "second round interview",
    category: status.STATUS,
    autoarchive: false,
    name: "Second Round Interview",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer is setting up a second round interview",
  },
  {
    event: "team interview",
    category: status.STATUS,
    name: "Team Interview",
    autoarchive: false,
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer is setting up a team interview",
  },
  {
    event: "task presentation",
    category: status.STATUS,
    autoarchive: false,
    name: "Task Presentation",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer is setting up a task presentation",
  },
  {
    event: "final interview",
    category: status.STATUS,
    autoarchive: false,
    name: "Final Interview",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer is setting up a final interview",
  },
  {
    event: "reference check",
    category: status.STATUS,
    autoarchive: false,
    name: "Reference Check",
    responsibleParty: "jobseeker review",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer is reviewing references",
  },
  {
    event: "employer accept",
    category: status.STATUS,
    autoarchive: false,
    name: "Employer has accepted",
    responsibleParty: "jobseeker view",
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer has shown interest in a JobSeeker",
  },
  {
    event: "employer reject",
    category: status.STATUS,
    autoarchive: true,
    name: "Employer has rejected",
    responsibleParty: null,
    note: "",
    singleUserOnly: false,
    staffOnly: false,
    description: "The employer has rejected a match",
  },
  {
    event: "hired",
    autoarchive: false,
    category: status.STATUS,
    name: "Hired",
    responsibleParty: null,
    note: "",
    singleUserOnly: true,
    staffOnly: false,
    description:
      "This JobSeeker has been hired! Note, this will archive all other matches and close the role. This page will navigate you to the matching dashboard page.",
  },
];

export const contactCardVisibilityOptions = [
  { value: "show no card", label: "Hide Contact Card" },
  { value: "jobseekers only", label: "Show Only JobSeekers" },
  { value: "employers only", label: "Show Only Employers" },
  { value: "jobseekers and employers", label: "Show JobSeekers and Employers" },
];

export const recipientLabels = {
  jobseeker_only: "JobSeeker",
  employer_only: "Employer",
  staff_only: "WorkMonger Staff",
  jobseeker_and_employer: "JobSeeker and Employer",
  staff_and_jobseeker: "WorkMonger Staff and JobSeeker",
  staff_and_employer: "WorkMonger Staff and Employer",
  staff_jobseeker_and_employer: "WorkMonger Staff, Employer, and JobSeeker",
};
