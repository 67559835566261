import { call, put } from 'redux-saga/effects';

import jwt from 'jsonwebtoken';
import * as authActions from 'actions/auth';
import * as errorActions from 'actions/errorHandling';

export function* retrieveUserInfo(action) {
  try {
    const token = localStorage.getItem('jwt');
    const decodedJWT = jwt.decode(token);

    const { data } = yield call(
      authActions.retrieveProfile,
      decodedJWT.user_id,
    );
    yield put(authActions.populateProfile(data));
  } catch (e) {
    yield put(
      errorActions.addError({
        message: e.message,
        module: 'auth',
      }),
    );
  }
}