import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

const WithTracker = ({ location }) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENDPOINT === "production") {
      ReactGA.set({
        page: location.pathname,
      });
      ReactGA.pageview(location.pathname);
    }
  }, [location.pathname]);

  return null;
};

export default withRouter(WithTracker);
