import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withSnackbar } from "notistack";

import { DateTime } from "luxon";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "actions/modal";

import withProfile from "HOC/withProfile";
import LoadingOverlay from "components/LoadingOverlay";
import Confetti from "components/Confetti";

class LandingPage extends Component {
  state = {
    showConfetti: false,
  };
  componentDidMount() {
    const { openModal, profile } = this.props;

    if (profile?.user) {
      if (profile.migratedFromTypeform && !profile.hasClaimedTypeformAccount) {
        this.setState({ showConfetti: true });
      }
      openModal({
        modalId: 12,
        variant: "REDIRECT_OPTIONS",
        canClose: false,
      });
    } else {
      this.timer = this.loginRedirect();
    }
  }

  componentDidUpdate({ profile: pastProfile }) {
    const { profile, openModal } = this.props;

    
    if (profile?.user !== pastProfile?.user && profile?.user) {
      if (profile.migratedFromTypeform && !profile.hasClaimedTypeformAccount) {
        this.setState({ showConfetti: true });
      }

      clearTimeout(this.timer);
      openModal({
        modalId: 12,
        variant: "REDIRECT_OPTIONS",
        canClose: false,
      });
    }
  }

  loginRedirect = () => {
    const {
      token,
      history,
      location: { pathname },
    } = this.props;
    const timeOfExp = token?.exp || 0;
    const isLoggedIn = DateTime.local() < DateTime.fromSeconds(timeOfExp);

    if (isLoggedIn) {
      /**
       * Waits for profile request to finish. On
       * very slow connections, this can take > 5s.
       */
      return setTimeout(() => {
        history.push("/login");
      }, 15_000);
    }

    history.push(`/login?redirectOnSuccess=${pathname}`);
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  render() {
    return (
      <>
        {this.state.showConfetti ? (
          <Confetti />
        ) : (
          <LoadingOverlay hideCircularProgress={this.props.profile?.user} />
        )}
      </>
    );
  }
}

export default compose(
  connect(null, (d) => bindActionCreators({ ...modalActions }, d)),
  withRouter,
  withProfile,
  withSnackbar
)(LandingPage);
