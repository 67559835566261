import React from "react";

import { Provider } from "react-redux";
import { useIsMobile } from "utils/cookbook";

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import NoSsr from "@material-ui/core/NoSsr";
import ErrorBoundary from "containers/ErrorBoundary";
import LoginRefresh from "containers/LoginRefresh";
import TermsOfServiceUpdatedBanner from "containers/TermsOfServiceUpdatedBanner";

import Routes from "routes";

import theme from "constants/theme";
import store from "store";

export default () => {
  const isMobile = useIsMobile();

  // @TODO add a setting for users to toggle this experimental feature on
  const prefersDarkMode = false; // useMediaQuery("(prefers-color-scheme: dark)");

  const userThemeByPreference = React.useMemo(
    () =>
      createTheme(
        prefersDarkMode
          ? {
              palette: {
                type: prefersDarkMode ? "dark" : "light",
                primary: {
                  main: "#3b86b0",
                },
                secondary: {
                  main: "#2193b0",
                },
              },
            }
          : theme
      ),
    [prefersDarkMode]
  );

  return (
    <Provider store={store}>
      <NoSsr>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: isMobile ? "top" : "bottom",
              horizontal: "left",
            }}
          >
            <MuiThemeProvider theme={userThemeByPreference}>
              <CssBaseline />
              <ErrorBoundary>
                <Routes />
                <TermsOfServiceUpdatedBanner />
                <LoginRefresh />
              </ErrorBoundary>
            </MuiThemeProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </NoSsr>
    </Provider>
  );
};
