import React, { Fragment, useState } from "react";
import cn from "classnames";
import { useActions, useUser, useGetRoleCompletionBySection } from "hooks";
import * as profileActions from "actions/profile";
import * as modalActions from "actions/modal";
import { redirectByRouteName } from "pages/EmployerProfile/organizationQuestions";

import { PieChart } from "react-minimal-pie-chart";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import Lock from "@material-ui/icons/Lock";

import sections from "./data/roleSections.json";

import s from "./RoleProgressPopover.module.css";

const RoleProgressPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSection, setOpenSection] = useState(null);
  const activeSubSection = null;

  const { activeQuestionIndex, role: { status, feedback } = {} } = useUser();
  const { setActiveQuestionIndex, openModal } = useActions({
    ...modalActions,
    ...profileActions,
  });
  const [completedSections] = useGetRoleCompletionBySection();
  const percentDone =
    Object.values(completedSections || {}).filter((done) => done).length * 25 ||
    activeQuestionIndex;
  const hasUnresolvedFeedback = feedback?.filter(
    ({ isResolved }) => !isResolved
  ).length;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? "role-popover" : undefined;

  const isLocked = percentDone < 11;

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        onClick={handleClick}
        className={s.rolePopover}
      >
        <div
          className={cn(s.pieChart, {
            [s.incomplete]: percentDone !== 100,
          })}
        >
          {percentDone !== 100 && status === "draft" ? (
            <PieChart
              data={[
                {
                  title: "Remaining",
                  value: 100 - percentDone,
                  color: "#d8d8d8",
                },
                { title: "Complete", value: percentDone, color: "green" },
              ]}
            />
          ) : null}
          {percentDone === 100 && status === "draft" ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                openModal({
                  modalId: 3,
                  variant: "CHANGE_ROLE_STATUS",
                  canClose: true,
                });
              }}
              variant="contained"
              className={cn(s.statusButton, s.submitForApproval)}
            >
              {hasUnresolvedFeedback ? "View Staff Feedback" : "Publish Role"}
            </Button>
          ) : null}
          {!["draft"].includes(status) ? (
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                openModal({
                  modalId: 3,
                  variant: "CHANGE_ROLE_STATUS",
                  canClose: true,
                });
              }}
              className={cn(s.statusButton, s.pendingApproval)}
            >
              Change Role Status
            </Button>
          ) : null}
        </div>
        <span className={s.navigateLabel}>Navigate</span> <KeyboardArrowDown />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: s.paper,
        }}
      >
        <div
          className={cn(s.popover, {
            [s.disabledPopover]: isLocked,
          })}
        >
          <div className={s.sectionInfo}>
            {sections.map(({ label, key, order, subSections }) => (
              <Fragment key={order}>
                <div
                  id="profile-sidenav"
                  className={cn(s.sectionName, {
                    [s.activeSection]: order === openSection,
                    [s.lastSection]: order === sections.length - 1,
                    [s.lastActiveSection]:
                      order === openSection && order === sections.length - 1,
                  })}
                  onClick={() => {
                    if (completedSections[label]) {
                      const openTo = order === openSection ? null : order;
                      setOpenSection(openTo);
                    }
                  }}
                >
                  <div className={s.row}>
                    {!completedSections[label] && <Lock className={s.lock} />}
                    {order === openSection && <KeyboardArrowUp />}
                    {order !== openSection && completedSections[label] && (
                      <KeyboardArrowDown />
                    )}
                    {label}
                  </div>
                </div>
                <div
                  className={cn(
                    s.subSection,
                    order === openSection && s.openSubSection
                  )}
                >
                  {order === openSection &&
                    subSections.map(({ label: subSection, routeTo }, index) => (
                      <div
                        onClick={(e) => {
                          const newActiveIndex = redirectByRouteName(routeTo);
                          setActiveQuestionIndex(newActiveIndex);
                          setAnchorEl(null);
                        }}
                        key={subSection}
                        className={cn(s.subSectionName, {
                          [s.activeSubSection]: subSection === activeSubSection,
                          [s.noMarginBottom]:
                            order === 4 && index === subSections.length - 1,
                        })}
                      >
                        {subSection}
                      </div>
                    ))}
                </div>
              </Fragment>
            ))}
          </div>
        </div>
        {isLocked ? (
          <div className={s.lockedOverlay}>
            You will need to answer more questions before unlocking navigation
          </div>
        ) : null}
      </Popover>
    </div>
  );
};

export default RoleProgressPopover;
